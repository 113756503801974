import { Component, OnInit } from '@angular/core';
import { Intercom } from 'ng-intercom';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
    model = {
        left: true,
        middle: false,
        right: false
    };

    focus;
    focus1;
    constructor(public intercom: Intercom) { }

    ngOnInit() {
        this.intercom.boot({
          app_id: "amphw8w4",
          // Supports all optional configuration.
          widget: {
            "activator": "#intercom" 
          }
        });
  
      }
}
